import React from "react";

function Header() {
  return (
    <div className="h-12 bg-red-500 text-white px-4 flex items-center justify-center text-center text-sm md:text-base cursor-pointer">
      Cabinets and Aluminum
    </div>
  );
}

export default Header;
