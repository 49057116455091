import React from "react";

const ContactPage = () => {

  const handleSubmit = (e) => {
    e.preventDefault();
  
    const formData = new FormData(e.target);
  
    fetch('/send-email', {
      method: 'POST',
      body: JSON.stringify({
        name: formData.get('name'),
        email: formData.get('email'),
        subject: formData.get('subject'),
        message: formData.get('message'),
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.ok) {
          // Email sent successfully
          console.log('Email sent successfully');
          // Add your success message or redirection logic here
        } else {
          // Email sending failed
          console.error('Error sending email');
          // Add your error handling logic here
        }
      })
      .catch((error) => {
        console.error('Error sending email', error);
      });
  };
  
  return (
    <div className="h-screen w-screen mx-auto grid grid-cols-12 gap-1 bg-red-500 p-1">
      <div className="header col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 bg-red-500 py-3">
        {/* Header content */}
        <div className="section-title text-center mb-3 pt-5">
          <div className="lg:text-3xl pb-3 font-bold text-white">
            Get In Touch
          </div>
          <p className="text-white">
            Directions and contact details
          </p>
        </div>
      </div>
      {/* <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 overflow-auto bg-red-500"> */}
        {/* Main Content */}
        {/* <form action="" method="POST" className="ml-10">
          <div className="mb-4">
            <input
              type="text"
              id="name"
              className="bg-white text-gray w-full max-w-md"
              placeholder="Full Name"
              required
            />
          </div>
          <div className="mb-4">
            <input
              type="email"
              id="email"
              className="bg-white text-gray w-full max-w-md"
              placeholder="example@domain.com"
              required
            />
          </div>
          <div className="mb-4">
            <input
              type="text"
              id="subject"
              className="bg-white text-gray w-full max-w-md"
              placeholder="Enter your subject"
              required
            />
          </div>
          <div className="mb-4">
            <textarea
              id="message"
              className="bg-white text-gray w-full max-w-md"
              placeholder="Type your message"
              style={{ height: "150px" }}
              required
            ></textarea>
          </div>

          <div>
            <button className="hover:shadow-form rounded-md py-2 px-5 text-base font-semibold text-white outline mt-3" 
            //  onClick={handleSubmit}
             >
              Submit
            </button>
          </div>
        </form>
      </div> */}
      <div className="col-span-12 sm:col-span-12 md:col-span-12  text-white bg-red-500 p-3  text-center">
        {/* Sidebar */}
        <div className="underline">
          <label>Address :</label>
        </div>
        <div className="pb-4">
          <p>Block D3</p>
          <p>34 H Larch Ave, Kensington, Qgebera</p>
          <p>(opp Mercantile Hospital)</p>
        </div>
        <div className="underline">
          <label>Contact :</label>
        </div>
        <div>
          <p>Cabinets : 069 709 5432 </p>
          <p>Aluminum : 068 158 7599</p>
         
        </div>
        <div className="underline mt-4">
          <label>Email :</label>
        </div>
        <div>
          <p>admin@abrtech.co.za </p>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
