import React, { useEffect, useState } from "react";

const data = [
  {
    id: 1,
    title: "Custom Designs To Fit Your Space",
    image: "/images/kitchen2.jpg",
  },
  {
    id: 2,
    title: "Customer Satisfaction",
    image: "/images/wardrobe1_resized.jpg",
  },
  {
    id: 3,
    title: "Quick and efficient",
    image: "/images/AluminumDoor1_resized.jpg",
  },
  {
    id: 4,
    title: "Creative and original",
    image: "/images/Windows1_resized.jpg",
  },
];

function Slider() {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(
      () =>
        setCurrentSlide((prev) => (prev === data.length - 1 ? 0 : prev + 1)),
      2000
    );

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex flex-col h-screen  md:h-[calc(100vh-9rem)] lg:h-[calc(100vh-4rem)] lg:flex-row bg-fuchsia-50">
      {/* Text Container */}
      <div className="flex-1  flex items-center justify-center flex-col gap-8 text-red-500 font-bold">
        <h1 className="text-2xl text-center uppercase p-4 md:p-10 md:text-2xl xl:text-3xl">
          {data[currentSlide].title}
        </h1>
        {/* <button className="bg-red-500 text-white py-4 px-8 rounded">
          Contact Now
        </button> */}
      </div>
      {/* Image Container */}
      <div className="w-full  relative">
        <img
          src={data[currentSlide].image}
          alt=""
          style={{ width: "100%", height: "80vh", objectFit: "cover" }}
        />
      </div>
    </div>
  );
}

export default Slider;
