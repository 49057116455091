export const services = [
  {
    id: 1,
    title: "Cabinets",
    description: "High-quality cabinets for your home.",
    img: "/images/smallkitchen.jpg",
    slug: "cabinets",
  },
  {
    id: 2,
    title: "Aluminum",
    description: "Aluminum products for various applications.",
    img: "/images/smallaluminumdoor.jpg",
    slug: "aluminum",
  },
  {
    id: 3,
    title: "Partitioning",
    description: "Custom partitioning solutions for spaces",
    img: "/images/smallpartition.jpg",
    slug: "partitioning",
  },
];

export const aluminum = [
  {
    id: 1,
    slug: "doors",
    title: "Doors",
    description:
      "Savor the taste of perfection with our exquisite Italian handmade pasta menu.",
    img: "/images/aluminum/doors/Door2.jpg",
  },
  {
    id: 2,
    slug: "windows",
    title: "Windows",
    description:
      "Burger Bliss: Juicy patties, bold flavors, and gourmet toppings galore.",
    img: "/images/aluminum/windows/Window2.jpg",
  },
  {
    id: 3,
    slug: "patios",
    title: "Patios",
    description: "ShopFronts done professionally and speedily",
    img: "/images/aluminum/patios/Patio1.jpg",
  },
];

export const cabinets = [
  {
    id: 1,
    slug: "kitchens",
    title: "Kitchens",
    desc: "Many beautiful designs",
    img: "/images/cabinets/kitchen/kitchen_main.jpg",
  },
  {
    id: 2,
    slug: "bathrooms",
    title: "Bathrooms",
    desc: "Expertly completed",
    img: "/images/cabinets/bathroom/Bathroom1.jpg",
  },
  {
    id: 3,
    slug: "wardrobes",
    title: "Wardrobes",
    desc: "Creatively done",
    img: "/images/cabinets/wardrobe/wardrobe1.jpg",
  },
];

export const cabinetItems = [
  {
    id: 1,
    title: "PushOpen",
    desc: "No handles and easy push to open cupboards",
    img: "/images/cabinets/kitchen/Rynstreet.jpg",
    category: "kitchens",
  },
  {
    id: 2,
    title: "Dark",
    desc: "Island in the middle",
    img: "/images/cabinets/kitchen/kitchen_main.jpg",
    category: "kitchens",
  },
  {
    id: 3,
    title: "Island",
    desc: "Island in the middle",
    img: "/images/cabinets/kitchen/kitchen_2.jpg",
    category: "kitchens",
  },
  {
    id: 4,
    title: "Downlights",
    desc: "With downlights on cupboards",
    img: "/images/cabinets/kitchen/kitchen_2_3.jpg",
    category: "kitchens",
  },

  {
    id: 5,
    title: "Vanity",
    desc: "No handles and easy push to open cupboards",
    img: "/images/cabinets/bathroom/bathroom2.jpg",
    category: "bathrooms",
  },
  {
    id: 6,
    title: "Dark",
    desc: "Island in the middle",
    img: "/images/cabinets/bathroom/bathroom1.jpg",
    category: "bathrooms",
  },
  {
    id: 7,
    title: "Complete",
    desc: "Full Cupboards in bathroom",
    img: "/images/cabinets/bathroom/bathroom_3.jpg",
    category: "bathrooms",
  },
  {
    id: 8,
    title: "Sliding doors",
    desc: "No handles and easy push to open cupboards",
    img: "/images/cabinets/wardrobe/wardrobe2.jpg",
    category: "wardrobes",
  },
  {
    id: 9,
    title: "Hanging One Side",
    desc: "Island in the middle",
    img: "/images/cabinets/wardrobe/wardrobe3.jpg",
    category: "wardrobes",
  },
  {
    id: 10,
    title: "Standard doors",
    desc: "Full Cupboards in bathroom",
    img: "/images/cabinets/wardrobe/wardrobe4.jpg",
    category: "wardrobes",
  },
];

export const aluminumItems = [
  {
    id: 1,
    title: "D1",
    desc: "No handles and easy push to open cupboards",
    img: "/images/aluminum/doors/Door1.jpg",
    category: "doors",
  },
  {
    id: 2,
    title: "D2",
    desc: "Island in the middle",
    img: "/images/aluminum/doors/Door2.jpg",
    category: "doors",
  },
  {
    id: 3,
    title: "D3",
    desc: "Island in the middle",
    img: "/images/aluminum/doors/Door3.jpg",
    category: "doors",
  },
  {
    id: 4,
    title: "W1",
    desc: "With downlights on cupboards",
    img: "/images/aluminum/windows/Window1.jpg",
    category: "windows",
  },

  {
    id: 5,
    title: "W2",
    desc: "No handles and easy push to open cupboards",
    img: "/images/aluminum/windows/Window2.jpg",
    category: "windows",
  },
  {
    id: 6,
    title: "W3",
    desc: "Island in the middle",
    img: "/images/aluminum/windows/Window3.jpg",
    category: "windows",
    
  },
  {
    id: 7,
    title: "P1",
    desc: "Full Cupboards in bathroom",
    img: "/images/aluminum/patios/Patio1.jpg",
    category: "patios",
  },
  {
    id: 8,
    title: "P2",
    desc: "No handles and easy push to open cupboards",
    img: "/images/aluminum/patios/Patio2.jpg",
    category: "patios",
  },
  {
    id: 9,
    title: "P3",
    desc: "Island in the middle",
    img: "/images/aluminum/patios/Patio3.jpg",
    category: "patios",
  },
  {
    id: 10,
    title: "P4",
    desc: "Full Cupboards in bathroom",
    img: "/images/aluminum/patios/Patio4.jpg",
    category: "patios",
  },
];