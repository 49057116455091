import React from "react";

import { Link , useNavigate} from "react-router-dom"; // Assuming you have React Router installed
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';

const cabinets = [
  {
    id: 1,
    slug: "kitchens",
    title: "Kitchens",
    desc: "Many beautiful designs",
    img: "/images/cabinets/kitchen/kitchen_main.jpg",
  },
  {
    id: 2,
    slug: "bathrooms",
    title: "Bathrooms",
    desc: "Expertly completed",
    img: "/images/cabinets/bathroom/Bathroom1.jpg",
  },
  {
    id: 3,
    slug: "wardrobes",
    title: "Wardrobes",
    desc: "Creatively done",
    img: "/images/cabinets/wardrobe/wardrobe1.jpg",
  },
];

const CabinetsPage = () => {
  const navigate = useNavigate();

  return (
    <div className="p-4 lg:px-20 xl:px-40 h-[calc(100vh-6rem)] md:h-[calc(100vh-6rem)] flex flex-col md:flex-row items-center relative">
      <button  onClick={()=> navigate(-1)} className="bg-red-500 hover:bg-red-600 text-white rounded-full w-8 h-8 absolute top-2 left-2 flex items-center justify-center">
                            <ArrowBackSharpIcon />
                        </button>
      {cabinets.map((category) => (
        <div
          key={category.id}
          className="w-screen items-center p-2 hover:bg-fuchsia-50 md:w-[50vw] xl:w-[33vw] xl:h-[50vh]"
        >
          <div className="flex flex-col md:flex-row">
            <h1 className="uppercase text-center font-bold text-2xl text-red-500">
              {category.title}
            </h1>
          </div>
          <Link to={`/cabinets/${category.slug}`}>
            <div className="relative flex-1 w-full h-[60vh]">
              <img
                src={category.img}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default CabinetsPage;
