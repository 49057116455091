import React from "react";
import { Link, useNavigate } from "react-router-dom";

import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';

const aluminum = [
  {
    id: 1,
    slug: "doors",
    title: "Doors",
    desc: "Savor the taste of perfection with our exquisite Italian handmade pasta menu.",
    img: "/images/aluminum/doors/Door2.jpg",
  },
  {
    id: 2,
    slug: "windows",
    title: "Windows",
    desc: "Burger Bliss: Juicy patties, bold flavors, and gourmet toppings galore.",
    img: "/images/aluminum/windows/Window2.jpg",
  },
  {
    id: 3,
    slug: "patios",
    title: "Patios",
    desc: "ShopFronts done professionally and speedily",
    img: "/images/aluminum/patios/Patio1.jpg",
  },
];

const AluminumCategoryPage = () => {
  const navigate = useNavigate();

  return (
    <div className="p-4 lg:px-20 xl:px-40 h-[calc(100vh-6rem)] md:h-[calc(100vh-6rem)] flex flex-col md:flex-row items-center relative">
       <button  onClick={()=> navigate(-1)} className="bg-red-500 hover:bg-red-600 text-white rounded-full w-8 h-8 absolute top-2 left-2 flex items-center justify-center">
                            <ArrowBackSharpIcon />
                        </button>
      {aluminum.map((category) => (
        <div
          key={category.id}
          className="w-screen items-center p-2 hover:bg-fuchsia-50 md:w-[50vw] xl:w-[33vw] xl:h-[50vh]"
        >
         
          <div className="flex flex-col md:flex-row">
          
            <h1 className="uppercase text-center font-bold text-2xl text-red-500">
              {category.title}
            </h1>
          </div>
          <Link to={`/aluminum/${category.slug}`}>
            <div className="relative flex-1 w-full h-[60vh]">
              <img
                src={category.img}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
          </Link>
        </div>
      ))}
   
    </div>
  );
};

export default AluminumCategoryPage;
