import React from "react";

function Footer() {
  return (
    <div className="h-12 md:h-24 p-4 lg:px-20 xl:px-40 text-red-500 flex items-center justify-between">
      <a href="/" className="font-bold text-xl">
        ABrTech
      </a>
      <p>@ ALL RIGHTS RESERVED.</p>
    </div>
  );
}

export default Footer;
