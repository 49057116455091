import React from "react";
import Menu from "./Menu";
import { Link } from "react-router-dom";

function Navbar() {
  //   const user = false;

  return (
    <div className="h-12 text-red-500 p-4 flex items-center justify-between border-b-2 border-b-red-500 md:h-20 lg:px-20 xl:px-10 xl:h-20">
      {/* LEFT LINKS */}
      <div className="hidden md:flex gap-2 flex-1 xl:text-left">
        <Link to="/">HomePage</Link>
        <Link to="/cabinets">Cabinets</Link>
        <Link to="/aluminum">Aluminum</Link>
        <Link to="/contact">Contact</Link>
      </div>
      {/* LOGO */}
      <div className="text-xl md:font-bold md:text-center flex-1 xl:text-4xl">
        <a href="/">AbrTech</a>
      </div>
      {/* MOBILE MENU */}
      <div className="md:hidden">
        <Menu />
      </div>
      {/* RIGHT LINKS */}
      <div className="hidden md:flex gap-3 items-center justify-end flex-1">
        <div className="md:absolute top-3 r-2 lg:static flex items-center gap-2 cursor-pointer bg-orange-300 px-1 rounded-md">
          <img src="/images/phone.png" alt="" width={20} height={20} />
          <span className="">069 709 5432</span>
          <img src="/images/phone.png" alt="" width={20} height={20} />
          <span className="">068 158 7599</span>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
