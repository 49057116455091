import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { cabinetItems, aluminumItems } from "../data";
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';

const CategoryPage = () => {
  const { category } = useParams();
  const navigate = useNavigate();
  
    let items = [];

    // Determine which items to display based on the category
    if (category === "kitchens" || category === "bathrooms" || category === "wardrobes") {
        items = cabinetItems.filter((item) => item.category === category);
    } else if (category === "doors" || category === "windows" || category === "patios") {
        items = aluminumItems.filter((item) => item.category === category);
    }

    return (
     
           
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 items-center gap-2 w-full mt-2">
                {items.map((item) => (
                    <div className="border-2 border-red-500 relative" key={item.id}>
                    
                       <button  onClick={()=> navigate(-1)} className="bg-red-500 hover:bg-red-600 text-white rounded-full w-8 h-8 absolute top-2 left-2 flex items-center justify-center">
                            <ArrowBackSharpIcon />
                        </button>
          
                        <img className="w-full h-auto" src={item.img} alt={item.title} />
                    </div>
                ))}
            </div>
        
    );
}

export default CategoryPage;

